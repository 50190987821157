import React, { useState, useEffect} from "react";
import {
  Grid,
  Button,
  Box,
  Image,
  Modal,
  createStyles,
  Text,
  Select, 
  Pagination,
  CopyButton, ActionIcon, Tooltip,
  Table,
  Progress,
  Flex,
 // Divider

} from "@mantine/core";
//import { useMetaMask } from "metamask-react";
import {  useLocalStorage } from "@mantine/hooks";
import Moment from 'react-moment';
import { IconCopy, IconCheck,IconRefresh } from '@tabler/icons-react';
import contractProvider from '../components/BrowserProviderOnly';
import {showNotification, updateNotification} from "@mantine/notifications";
import { FiCheckCircle} from "react-icons/fi";
import { ethers } from "ethers";


const useStyles = createStyles((theme) => ({
  dataBoxPadding: {
    padding: theme.spacing.lg,
  },
  dataBox: {
    fontSize: 15,
    //display: "flex",
    // lineHeight: 32,
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[5]
        : theme.colors.gray[3],
    color:
      theme.colorScheme === "dark"
        ? theme.colors.gray[3]//theme.colors.indigo[4]
        : theme.colors.dark[6],
    // padding: theme.spacing.xl,
    marginBottom: "10px",
    borderRadius: theme.radius.md,
    //boxShadow: "5px 5px 2px 0px lightgray",
    minWidth: "350px",
    alignSelf: "center",
    padding: "10px 20px",

    [theme.fn.largerThan("lg")]: {
      width: 1180,
    }
  },
  refreshBox: {
    display:"flex",
    flexDirection: "row",
    //position: "relative",
  },
  refreshBoxSelect:{
    flexGrow: 1,  
    textAlign: "center",

  },
  refresh: {
    alignSelf: "flex-end",
    marginRight: 10,
  },
  modalgridbox: {
    minheight: "10px",
    fontWeight: 'bold',
    paddingLeft: 5
  },

  boldFont: {
    fontWeight: 700,
  },
  divider: {
    borderColor: theme.colors.gray[6],
    borderRadius:"1px",
    borderWidth: "0.8px",
  },
  textMargin:{
    margin: "6px auto",
    [theme.fn.smallerThan("sm")]: {
      fontSize: 11,
    },
  },
}));


interface NFT {
  id: number;
  name: string;
  description: string;
  image: string;
  feature: string;
  degree: string;
  angle: string; //number
  gColor1: string; //number
  gColor2: string; //number
  created: string; //number
}

type NFTs = NFT[];

const NFTsGrid: React.FC = () => {
  Moment.globalFormat = 'HH:mm:ss DD/MM/YYYY';
  const { classes} = useStyles(); // стейт стилей для компонентов
  const [opened, setOpened] = useState(false); //стейт модального окна открыт закрыт
  const [modalContent, setModalContent] = useState<NFT>(); //контекст для модального окна
  //const [modalContent, setModalContent] = useState(""); //контекст для модального окна
  const [activePage, setPage] = useState<number>(1);  //стейт активной страницы
  const [pageAmount, setPageAmount] = useState<number>(10); //кол-во страниц на странице мин кол-во страниц 10 должно быть 
  const [value, setValue] = useLocalStorage<string>({ 
    key: 'nft-per-page',
    defaultValue: '48',
    getInitialValueInEffect: true,
  });//значение в локал сторэдже для дропдаун меню по кол-у nft на страницу
  //const [value, setValue] = useState<string | null>("8"); //значение для дропдаун меню по кол-у nft на странцу
  //const [valueIndex, setValueIndex] = useState<number>(-1);
  const [minted, setMinted] = useState<number>(0);
  const [loader, setLoader] = useState(false); //page loader state
  const [tokenids, setTokenId] = useState<NFTs>([]);
  const [i, setI] = useState<number>(0);
  const [maxTokenAmount, setMaxTokenAmount] = useState<number>(0);
  const correctChainId = "0xc1";
  // const [isCorrectChain, setCorrectChain] = useState<boolean>(false);
  // const correctChainId = "0xc1";
  // const { switchChain, account, chainId } = useMetaMask();

  const resetState = () => {
    setTokenId([]);
    setI(Number(1+(activePage-1)*Number(value)));
  };

async function NetworkStatusCheck() {
  // let provider_
  // try {
  //   provider_ = new ethers.providers.JsonRpcProvider('https://cemchain123.com');
  //   if (provider_._network){
  //     console.log('Blochain connected', provider_.network.chainId);
  //     console.log(provider_.connection);
  //   }
    
  // } catch (error) {
  //   if (!provider_?._network) {
  //     console.log("From provider catch:",error);
  //   }
  try {
  const provider = new ethers.providers.JsonRpcProvider('https://cemchain.com');
  //const provider2 = new ethers.providers.JsonRpcProvider('https://cemchain.com');
  // console.log('1 provider',provider.connection);
  // console.log('2 provider',provider2.connection);
  const response1 = await provider.getNetwork();
  //const response2 = provider2.getNetwork();
  console.log("chainId:",response1.chainId);
  if(response1.chainId !== Number(correctChainId)) {
    //alert("You are not connected to the Goerli network!")
    console.log("chainId 1 :",response1.chainId);
    return;
   }
  console.log('1 getNetwork:',response1);
  //console.log('2 getNetwork:',response2);
  } catch (error) {
    console.log('Error:',error);
  }
  
  //console.log(connection.url('https://cemchain.com'))
  //return (<div>Hello</div>);
  // //}
  // try {
  //   const provider3 = new ethers.providers.JsonRpcProvider('https://cemchain.com');
  //   const response3 = await provider3.getNetwork();
  //   console.log('3 getNetwork:',response3);
  // } catch (error:any) {
  //   console.log(error.code);
  // }
}
 
  async function getDatas() {
  
      try {
        const requestData = await contractProvider.tokenURI(i);
        const response = await fetch(requestData);
        const obj = await response.json();
        const newtokenid = {
          id: i,
          name: obj.name,
          description: obj.description,
          image: obj.image,
          feature: obj.feature,
          degree: obj.degree,
          angle: obj.angle,
          gColor1: obj.gColor1,
          gColor2: obj.gColor2,
          created: obj.created
        };
        setTokenId([...tokenids, newtokenid]);
        console.log("id =", newtokenid.id);
      } catch (err) {
        console.log(err);

      }
  }
  async function getMintedAmount(){
    try {
      const _currentTokenSupply = await (contractProvider.mintSupply());
      console.log("Totally tokens minted:", Number(_currentTokenSupply));
      setMinted(Number(_currentTokenSupply));   
      setMaxTokenAmount(Number(await contractProvider.MAX_NFT_SUPPLY()));
      if(Number(_currentTokenSupply)>0){
        setPageAmount(Math.ceil(Number(_currentTokenSupply)/Number(value)));
        }
    } catch (error) {
      console.log(error);
    }
     
  }
  //этот юзэффект для того чтобы корректно отрисовалось дефолтное кол-во страниц при начальной загрузке страницы
  // и однократно считывался стейт для кол-ва сминченых токенов
  useEffect(() => {
    setLoader(true);
    (async () => {
      await getMintedAmount();
      setLoader(false);
      setPage(1);
    })();
  }, []);
//1
//useeffect for loader
  useEffect(() => {
    if(loader === true){
    showNotification({
      id:'Load',
      loading: true,
      title: 'Loading data',
      message: 'Please wait a few seconds...',
      autoClose: 3000 
      //|| (loader===opened),
    });
  }
  if(loader === false){
    updateNotification({
      id: 'Load',
      title: 'Loading is complete!',
      message: '',
      color: "green",
      icon: <FiCheckCircle size={16} />,
      autoClose: 2000,
    });
  }

  }, [loader]);
  
  
//этот эффект срабоатывает каждый раз при смене страницы 
// он обнуляет массив структуры токенов и задает стартовое значение для индекса токенов.
useEffect(() => {
  setTokenId([]);
  if (activePage>1){
    setI((1+(activePage-1)*Number(value)));
  }
  if (activePage===1){
    setI(0);
  }
  console.log("From NFTs: Page changed to", activePage);
  console.log("From NFTs: i changed to", i);
}, [activePage]);

useEffect(() => {
  setLoader(true);
 
  (async () => {
    
     if ((i < ((activePage)*Number(value)))&&(i <= minted)) {
        await getDatas(); 
        setI((prev) => prev + 1);
        console.log("i =", i);
     }
     if ((i === ((activePage)*Number(value)))&&(i <= minted)) {
      await getDatas(); 
      console.log("i =", i);
      setLoader(false);
    }
  })();
}, [i]);

  const dropDownData = [
    {
      label: "24 NFTs per page",
      value: "24",
    },
    {
      label: "48 NFTs per page (default)",
      value: "48",
    },
    {
      label: "192 NFTs per page",
      value: "192",
    },
    {
      label: "576 NFTs per page",
      value: "576",
    }
  ];
  
  const handleChange = (value: string) => {
    setTokenId([]);
    //setValueIndex(dropDownData.findIndex((d) => d.value === value));
    setValue(value);
    setPageAmount(Math.ceil(minted/Number(value)));
    setPage(1);
    setI(1);
    console.log("Dropdown value: ", value);
    //console.log("ValueIndex: ", valueIndex);
    console.log("Pages amount: ", pageAmount);

  };

const mitems = tokenids.map(tokenid => {
  return (
    <>    
      <Grid.Col style={{
              //minWidth: 400, 
              maxWidth: 520,
              padding: "5px",
              textAlign: "center", 
              justifyContent: "space-around"
              }} 

              sm={6} lg={4} xl={3} key={tokenid.id}>
        <Text>{tokenid.name}</Text>
        <Image radius="md" src={tokenid.image} alt="NFT image" title={tokenid.name} />
        <Button style={{ marginTop: 5, marginBottom: 5 }} onClick={() => {setOpened(true); setModalContent(tokenid);}}>Detail {tokenid.name}</Button>
        <Modal
          opened={opened}
          onClose={() => setOpened(false)}
          //onClose={() => handlers.close()}
          size="md"
          title="NFT Details"
          key={`Modal_${tokenid.id}`}
          overlayProps={{opacity: 0.2}}
          transitionProps={{duration:100,  transition: "skew-up"}}
        >
            {<>
            <Flex 
                mih={15}
                gap="none"
                justify="flex-end"
                align="center"
                direction="row"
                wrap="nowrap">
              <Text 
                  fz={13}
                  ta="right"
                  style={{ flexGrow: 1, paddingRight: 23 }} 
                  className={classes.boldFont}>{modalContent?.description}</Text>
              {modalContent?.image && <CopyButton value={modalContent.image} timeout={1000}>
                {({ copied, copy }) => (<Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
                {copied ? <IconCheck size={16} /> : <IconCopy size={16} />}
                </ActionIcon></Tooltip> )}</CopyButton>}
            </Flex>
                <Image src={modalContent?.image} alt="NFT image" title={modalContent?.name}/>
                <Table highlightOnHover={true} horizontalSpacing="md" verticalSpacing="xs" striped={true} withBorder={true} withColumnBorders={true} >
                <tbody>
                  <tr key="name">
                    <td>Name: </td>
                    <td className={classes.boldFont}>{ modalContent?.name }</td>
                  </tr>
                  <tr key="feature">
                    <td>Feature: </td>
                    <td className={classes.boldFont}>{modalContent?.feature}</td>
                  </tr>
                  <tr key="degree">
                    <td>Degree: </td>
                    <td className={classes.boldFont}>{modalContent?.degree}</td>
                  </tr>
                  <tr key="angle">
                    <td>Angle: </td>
                    <td className={classes.boldFont}>{modalContent?.angle}</td>
                  </tr>
                  <tr key="FirstColor">
                    <td>1st gradient color: </td>
                    <td className={classes.boldFont}>{modalContent?.gColor1}</td>
                  </tr>
                  <tr key="SecondColor">
                    <td>2nd gradient color: </td>
                    <td className={classes.boldFont}>{modalContent?.gColor2}</td>                    
                  </tr>
                  <tr key="Creation date">
                    <td>Creation date: </td>
                    <td className={classes.boldFont}><Moment unix>{modalContent?.created}</Moment></td>
                  </tr>
                  </tbody>
                </Table>
       
            </>}

        </Modal>
      </Grid.Col>
    </>

  );
});

// function WrongNetwork() {
  
//   return (
//     <Button onClick={() => switchChain(correctChainId)}
//             size="xl" 
//             title="Press to connect Metamask">
//               Switch to CryptoEmergency
//     </Button>
//   )
// }
  // <Group  style={{display: "flex", 
  // flexDirection: "row",
  // justifyContent: "flex-start", 
  // }}>
//theme.fn.linearGradient(90, 'yellow', 'green', 'red'
  return (
    <>

    <Box style={{ padding: 10}}>
      <Box className={classes.dataBox}>
      <Text align="center">Current token supply: {minted} / {maxTokenAmount}</Text>
      <Progress  
        title="Minting progress bar" 
        size="xl"
        radius="sm"
        value={100*(minted/maxTokenAmount)}
        styles={(theme)=>(
          { 
            //marginBottom:10, 
            bar: {
                //theme.fn.linearGradient(90, 'yellow', 'green', 'red'),
                background:
                  theme.colorScheme === "dark"
                    ? theme.colors.gray[3]
                    : theme.colors.gray[6],
                    marginBottom: 50,
                 }}
        )}
      />
      <Box className={classes.refreshBox}>
        <ActionIcon
              className={classes.refresh}
              variant="outline"
              size={42}
              title="Refresh tokens"
              onClick={resetState}
            >
              {<IconRefresh size={24}/>}
        </ActionIcon>         
        <Select
              className={classes.refreshBoxSelect}
              //disabled={i.toString()!==value}
              styles={(theme) => ({
                item: {
                  '&[data-selected]': {
                    '&, &:hover': {
                      backgroundColor:
                        theme.colorScheme === 'dark' ? theme.colors.gray[9] : theme.colors.gray[2],
                      color: theme.colorScheme === 'dark' ? theme.white : theme.colors.gray[9],
                    },
                  },
                  // applies styles to hovered item (with mouse or keyboard)
                  '&[data-hovered]': {},
                },
                }
                )}
                title="Drop down select menu" 
                label="How many NFTs to be displayed?"
                placeholder="Pick amount to be displayed"
                data={dropDownData}
                value={value}
                onChange={handleChange}
                maxDropdownHeight={400}
                //defaultValue="12"
                filter={(value, item) => item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
                  item.description.toLowerCase().includes(value.toLowerCase().trim())} />
      </Box>
    </Box>
    <Grid gutter={"sm"} justify={"space-around"} style={{padding: 5}}>
    {tokenids && <>{mitems}</>}
    </Grid>
    <Pagination 
          style={{ margin: "10px" }} 
          position="center" 
          siblings={2} 
          value={activePage} 
          onChange={(page)=>(setPage(page))} 
          total={pageAmount} 
          color="violet.5" 
          size="lg" 
          radius="md" 
          withEdges 
    />
 </Box>

    </>
      
  );
};
export default NFTsGrid;
// {isCorrectChain === false &&
//   <Box title="Chain data" className={cx(classes.dataBox, classes.dataBoxPadding)}>
      
//       <Text  className={classes.textMargin}>My account : {account}</Text>
//         <Divider className={classes.divider} />

//       <Text className={classes.textMargin} color={isCorrectChain ? "Default" : "red"}>ChainID: {Number(chainId)}</Text>
//         <Divider className={classes.divider} />
//     </Box>
//  }
// {isCorrectChain === true && <>
// </>}
//<Button onClick={NetworkStatusCheck}>NetworkCheck</Button> 