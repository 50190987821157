import React from 'react';
import MainMint from '../components/MainMint';
//import {Box} from "@mantine/core";


const Main: React.FC = () =>{
    return ( 
      <>
        <MainMint/>
      </>
     );
}
export default Main;