import React from "react";
import { ActionIcon, useMantineColorScheme } from "@mantine/core";
import { FaMoon, FaSun } from "react-icons/fa";

const DarkLightButton: React.FC = () => {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === "dark";

  return (
    <ActionIcon
      variant="outline"
      onClick={() => toggleColorScheme()}
      title="Toggle site color scheme"
      size={35}
      sx={(theme) => ({
        color:
          theme.colorScheme === "dark"
            ? theme.colors.yellow[3]
            : theme.colors.violet[5], //grape.8
        '&:hover': {    
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.dark[6]
            : theme.colors.gray[0],}
       
      })}
    >
      {dark ? <FaSun size={22} /> : <FaMoon size={22} />}
    </ActionIcon>
  );
};
export default DarkLightButton;
