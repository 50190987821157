import React from "react";
import { NavLink} from "react-router-dom";
import {
  createStyles,
  Header,
  Container,
  Group,
  Burger,
  Paper,
  Transition,
  rem,
  Button,
  Text,
  Badge
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useMetaMask } from "metamask-react";

const HEADER_HEIGHT = rem(50);

const useStyles = createStyles((theme) => ({
  root: {
    //position: "relative",
    zIndex: 1,
    //width:"100vw", //дает расширение на всю страницу, но тогда нужна скрукление убирать углов   
    //maxWidth: "1200px",
    position: "sticky",
    padding: 0,
    backgroundColor:
    theme.colorScheme === "dark"
      ? theme.colors.dark[4]
      : theme.colors.gray[3],
      
    [theme.fn.smallerThan(1200)]: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      },
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  dropdown: {
    position: "absolute",
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: "hidden",

    [theme.fn.largerThan("sm")]: {
      display: "none"
    }
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%", 
    maxWidth: "1200px",
    width:"100vw",      
  },

  links: {
    // maxWidth: 600,
    //minWidth: "500px",
    padding: "1px 3vw",
      [theme.fn.smallerThan("xs")]: {
        display: "none",

      },
      [theme.fn.smallerThan("sm")]: {
        padding: "1px 0 1px 1vw",
      },
      flexWrap: "nowrap",

    },

  burger: {
    marginRight: "auto", //theme.spacing.sm
    paddingLeft: "6vw",
    [theme.fn.largerThan("xs")]: {
      display: "none",
    },
  },
  connectBtn: {
    // backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[7],
    // color:  theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[0],
    // '&:hover': {
    //              backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[6],
    //             // color: theme.colors.cyan[2],
    //             },
    marginRight: "5vw",
    // [theme.fn.smallerThan("sm")]: {
    //   marginRight: "3vw",
    // },
    borderRadius: theme.radius.sm,
   },
  
   connectBadge: {
    // backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[7],
    // color:  theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[0],
    marginRight: "5vw",
    borderRadius: theme.radius.sm,
    userSelect: "none",
    minHeight: "36px",
    minWidth: "160px",
    // [theme.fn.smallerThan("sm")]: {
    //   marginRight: "3vw",
    // },

   },


  link: {
    display: "block",

    lineHeight: 1,
    padding: "8px 2vw",//8px 12px
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    fontSize: theme.fontSizes.md,
    fontWeight: 600,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: 
          theme.colorScheme === "dark"
              ? theme.colors.dark[7]
              : theme.colors.gray[4],
      color: 
          theme.colorScheme === "dark"
              ? theme.colors.violet[3]
              : theme.colors.violet[6],
  
    },
  },
}));


interface HeaderResponsiveProps {
    links: { link: string; label: string; title: string }[];
  }
function HeaderResponsive({ links }: HeaderResponsiveProps) {
  const [opened, { toggle, close }] = useDisclosure(false);

  const { classes, cx } = useStyles();

   
  const { status, connect, account } = useMetaMask();
  const shortAccount = String(account).substring(0,5) + "..." + String(account).substring(String(account).length-5,String(account).length);

  async function singleTimeConnection() {
    if(status !== "connected" ){
      //setLoader(true); 
      try {
        connect();
      } catch (error) {
        console.log(error);
      }
      finally{
        //setLoader(false); 
      }
    }
  }

  const items = links.map((link) => (
    <NavLink
      key={link.label}
      to={link.link}
      title={link.title}
      className={({isActive}) => isActive ? cx(classes.link,classes.linkActive) : cx(classes.link)}
    >
      {link.label}
    </NavLink>
  ));
  // const dropDownItems = links.map((link) => (
  //   <NavLink
  //     key={link.label}
  //     to={link.link}
  //     title={link.title}
  //     className={({isActive}) => isActive ? cx(classes.link,classes.linkActive) : cx(classes.link)}
  //   >
  //     {link.label}
  //   </NavLink>
  // ));
// mb={120}
//spcing={5}
  return (
    <Header height={HEADER_HEIGHT} className={classes.root}>
      <Container className={classes.header}>

        <Group className={classes.links}>
          {items}
        </Group>

        <Burger
          opened={opened}
          onClick={toggle}
          className={classes.burger}
          size="md"
        />

        <Transition transition="pop-top-right" duration={120} mounted={opened}>
          {(styles) => (
            <Paper 
                className={classes.dropdown} 
                withBorder 
                style={styles}
                onMouseLeave={close}
            >
                <Text fz={10} ta="center" style={{margin: 4,}}>FaithToken page menu</Text>
                {items}
            </Paper>
          )}
        </Transition>
        { status !== "connected" ? (
        <Button 
              onClick={singleTimeConnection}
              title="Press to connect Metamask"
              size="md"
              //variant="white"
              className={classes.connectBtn}
          >
            Connect
        </Button>)
        : (
          <Badge
                title={ "Account connected" }
                size="lg"
                //variant="white"
                tt="unset"
                fz={15}
                className={classes.connectBadge}
            >
              {shortAccount}
          </Badge>)
        }
      </Container>
    </Header>
  );
}
export default HeaderResponsive;
