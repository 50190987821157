import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { MetaMaskProvider } from "metamask-react";
// import { QueryClient,  QueryClientProvider} from '@tanstack/react-query'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

// const queryClient = new QueryClient({  
//   defaultOptions: {
//     queries: {
//       refetchOnWindowFocus: false,
//       //refetchOnReconnect: false,
//       keepPreviousData: true,
//       //notifyOnChangeProps: ['data'],
//       }
//     }
//   });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

  <MetaMaskProvider>
    <BrowserRouter> 
        <App/>     
    </BrowserRouter>
  </MetaMaskProvider> 
 
);
// with react querry
  // <QueryClientProvider client={queryClient}>
  // <ReactQueryDevtools />
  // <App/>
  // </QueryClientProvider>
