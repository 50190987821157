import React from 'react';
import NFTsGrid from '../components/NFTsGrid';
import {Box, Text} from "@mantine/core";

const NFTs: React.FC = () => {
    return ( 
      <>
        <Box style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Text fw={700} style={{ fontSize: "clamp(8px,1vw,16px)", margin: "1vh auto" }}>This page shows all minted NFTs</Text>
            <NFTsGrid/>
        </Box>
      </>
    );
}
 
export default NFTs;
