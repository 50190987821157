import React, { useState, useEffect, useRef} from "react";
import {
  Grid,
  Flex,
  Button,
  Box,
  Image,
  Modal,
  createStyles,
  Text,
  Select,
  Pagination,
  CopyButton, ActionIcon, Tooltip,
  Table,
  Divider,
  TextInput
} from "@mantine/core";

import { useDisclosure, useLocalStorage } from "@mantine/hooks";
import Moment from 'react-moment';
import { IconCopy, IconCheck, IconRefresh } from '@tabler/icons-react';
import { useMetaMask } from "metamask-react";
import contract from '../components/BrowserProvider';
import {showNotification, updateNotification} from "@mantine/notifications";
import { FiCheckCircle, FiXCircle } from "react-icons/fi";
import { BigNumber, ethers } from "ethers";

import { useForm } from '@mantine/form';



//height: '100vh',
const useStyles = createStyles((theme) => ({
  mainBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "none", //
    padding: "5px",
    alignSelf: "center",
    
    
  },
  modalgridbox: {
    minheight: "10px",
    fontWeight: 'bold',
    paddingLeft: 5
  },
  boldFont: {
    fontWeight: 700,
  },
  dataBox: {
    fontSize: 15,
    // lineHeight: 32,
    zIndex: 1,
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[5]
        : theme.colors.gray[3],
    color:
      theme.colorScheme === "dark"
        ? theme.colors.gray[3]//theme.colors.indigo[4]
        : theme.colors.dark[6],
    // padding: theme.spacing.xl,
    marginBottom: "10px",
    borderRadius: theme.radius.md,
    
    minWidth: "350px",
    alignSelf: "center",
    [theme.fn.largerThan("sm")]: {
      fontSize: 14,
      //boxShadow: "5px 5px 2px 0px lightgray",
    },
    [theme.fn.largerThan("lg")]: {
      width: 1180,
    }

    //justifyContent: "center"
  },
  dataBoxPadding: {
    padding: theme.spacing.lg,
  },
  chainBtnBox: {
    display: "flex",
    justifyContent: "center",
    margin: 10,
  },
  divider: {
    borderColor: theme.colors.gray[6],
    borderRadius:"1px",
    borderWidth: "0.8px",
  },
  textMargin:{
    margin: "6px auto",
    [theme.fn.smallerThan("sm")]: {
      fontSize: 11,
    },
  },
  refresh: {
    alignSelf: "flex-end",
    marginRight: 10,
  },
  refreshBox: {
    display:"flex",
    flexDirection: "row",
  },
  refreshBoxSelect:{
    flexGrow: 1,  
    textAlign: "center",
    //margin: "1vh auto",
    //alignSelf: "flex-end",

    // [theme.fn.smallerThan("sm")]: {
    //   fontSize: 11,
    // },
  },


  // imageGrid:{
  //   minWidth: 300, 
  //   pointerEvents: "auto"
  // }


}));

interface NFT {
  //index: number,
  id: number;
  name: string;
  description: string;
  image: string;
  feature: string;
  degree: string;
  angle: string; //number
  gColor1: string; //number
  gColor2: string; //number
  created: string; 
}

type NFTs = NFT[];

const MyNFTsGrid: React.FC = () => {

  const { status, connect, account, chainId, switchChain } = useMetaMask();
  const shortAccount = String(account).substring(0,5) + "..." + String(account).substring(String(account).length-5,String(account).length);
 

  Moment.globalFormat = 'HH:mm:ss DD/MM/YYYY';
  const { classes, cx } = useStyles(); // стейт стилей для компонентов

  //contract state data
  const [minted, setMinted] = useState(0);
  const [myTokenAmount, setMyTokenAmount] =  useState(0);

 // const [opened, setOpened] = useState<boolean>(false); //стейт модального окна открыт закрыт
  const [openedModal, handlers] = useDisclosure(false); //второй вариант модального окна открыт закрыт
  const [modalContent, setModalContent] = useState<NFT>(); //контекст для модального окна
  //const [modalContent, setModalContent] = useState(""); //контекст для модального окна
  const [activePage, setPage] = useState<number>(0);  //стейт активной страницы
  const [pageAmount, setPageAmount] = useState<number>(3); //кол-во страниц на странице мин кол-во страниц 3 должно быть 
  const [value, setValue] = useLocalStorage<string>({ 
    key: 'nft-per-page',
    defaultValue: '12',
    getInitialValueInEffect: true,
  });
  //const isMobile = useMediaQuery("(max-width: 600px)"); //
  //const [value, setValue] = useState<string | null>("16"); //значение для дропдаун меню по кол-у nft на странцу
  //const [valueIndex, setValueIndex] = useState<number>(-1);
  
  const [txResponse, setTxResponse] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [signerConnected, setSignerConnected] = useState<boolean>(false);
  const [maxTokenAmount, setMaxTokenAmount] = useState<number>(0);
  const [isCorrectChain, setCorrectChain] = useState<boolean>(false);
  const correctChainId = "0xc1";
//"0x539"
  const [loader, setLoader] = useState<boolean>(false); //page loader state

//const [myTokenIds, setMyTokenId] = useState<ArrayOfMyTokens>([]);
  const [myTokenIds, setMyTokenId] = useState<NFTs>([]);
  const [j, setJ] = useState<number>(-1); //номер токена
  const addressRef = useRef<HTMLInputElement>(null);

  function resetState() {
    setMyTokenId([]);
    setJ((activePage-1)*Number(value));
    setPage(activePage);
  };

  // вызывается при смене status, account, chainId и инициализирует основной стейт

  useEffect(()=>{
    (async ()=>{
      if ((status === "connected") && (chainId === correctChainId)) {
        setLoader(true);
        setMyTokenId([]);
        setPage(0);
        setJ(-1);
        try {
          const connectAcc = contract.connect(String(account));
          await connectAcc.signer.getAddress()
            .then((signer_: string) => {
              console.log("This address:", signer_, "is signer = ", connectAcc.signer._isSigner);
              setSignerConnected(connectAcc.signer._isSigner);      
              })
        
          
          const currentTokenSupply_ = await (contract.mintSupply());
          console.log("Totally tokens minted:", Number(currentTokenSupply_));
          setMinted(Number(currentTokenSupply_));
          const myTokens_ = await contract.balanceOf(String(account));
          console.log("Tokens minted for", account, "is", Number(myTokens_));
          setMyTokenAmount(Number(myTokens_));
          setMaxTokenAmount(Number(await contract.MAX_NFT_SUPPLY()));

        } catch(error:any) {
          console.log("Error code:", error.code);
          console.log (error.reason);
          console.log(error.message);
          setErrorMessage("Data request failed. Code: " + error.code);
       } finally{
         setLoader(false);
         setPage(1);
         console.log("Loader is: ",loader);
       }
   };
 })();
 },[status, account, chainId])


// как только мы считали количество токенов и оно >0 то меняем стейт у I 

async function getMyDatas() {
    if (account !== null) {
        if (myTokenAmount !== 0) {
          console.log("My tokens > 0, fetching...");
           try {
            const ownersTokenID = await contract.tokenOfOwnerByIndex(account,j);
                const requestData = await contract.tokenURI(ownersTokenID);
                const response = await fetch(requestData);
                const obj = await response.json();
                const newtokenid = {
                  id: j,
                  name: obj.name,
                  description: obj.description,
                  image: obj.image,
                  feature: obj.feature,
                  degree: obj.degree,
                  angle: obj.angle,
                  gColor1: obj.gColor1,
                  gColor2: obj.gColor2,
                  created: obj.created
                  };
                setMyTokenId([...myTokenIds, newtokenid]);
                }
              catch (err:any) {
                console.error(err.code);
              }             
          }
        }
      }
  //useeffect for loader
  useEffect(() => {
    if(loader === true){
      showNotification({
        id:'Load',
        loading: true,
        title: 'Loading data',
        message: 'Please wait a few seconds...',
       // withCloseButton: false,
        //autoClose: 3000,
    });
  }
  if(loader === false){
    updateNotification({
      id: 'Load',
      title: 'Loading is complete!',
      message: txResponse ==="" ? 'Data loaded': txResponse,
      color: "green",
      icon: <FiCheckCircle size={16} />,
      onClose: ()=>(setTxResponse("")),
    });
  }
  
  }, [loader]);


  //useeff for Error Message
useEffect(() => {
  if(errorMessage!==""){
    showNotification({
      id:'Error',
      title: 'Error',
      message: errorMessage,
      color: "red",
      icon: <FiXCircle size={16} />,
      onClose: ()=>(setErrorMessage("")),
      //autoClose: 3000,
      //можно делать по условию которое выдает true или false
  });
}
}, [errorMessage]);
//юзэффект при выборе страницы
useEffect(() => {
  setMyTokenId([]);
  setPageAmount(Math.ceil(myTokenAmount/Number(value)));
  if (activePage>1){
    setJ(((activePage-1)*Number(value)));
  }
  if (activePage===1){
    setJ(0);
  }
  console.log("Page changed to", activePage);
  console.log("j changed to", j);
}, [activePage]);

//hook checks if chain is correct and sets bool state
useEffect(() => {

  if(chainId != null) {
  if (chainId === correctChainId) {
    setCorrectChain((prev)=>(prev = true));
    console.log('Chain is correct');
    return;
  }
    if (chainId !== correctChainId) {
      setCorrectChain((prev)=>(prev = false));
      console.log('Chain is wrong');
      showNotification({
        id: 'WrongChain',
        title: 'Wrong blockchain!',
        message: 'Please press the button to switch back to correct chain.',
        color: "red",
        icon: <FiXCircle  size={16} />,
        //autoClose: 4000,
      });
      return;
    }

  }

  
}, [chainId]); 


//юзэффект для формирования массива myTokenIds
useEffect(() => {
   (async () => {
    //if(status === "connected") {
    setLoader(true);
    console.log("UseEffect started");
    //(i < ((activePage)*Number(value)))&&
   // if ((j<Number(value)) && (j< myTokenAmount-Number(value))){
    if ((myTokenAmount > 0) && (j < myTokenAmount)) {
      if ((j < ((activePage)*Number(value)-1))) {
        await getMyDatas(); 
        console.log("getMyDatas completed");
        setJ((prev) => prev + 1);
        console.log("J =", j);
     }
     //это условие для последней картинки на странице, чтобы индекс для первого на следующей странице не менялся следующий 
     // нет , это условие , чтобы i не выходил за рамки границ minted
      if (( j === (activePage)*Number(value)-1)) {
        await getMyDatas(); 
        console.log("J =", j);
        setLoader(false);
    }
  } 
 // }
 // }
  })
  ();
}, [j]);


 
const dropDownData = [
  {
    label: "8 NFTs per page",
    value: "8",
  },
  {
    label: "12 NFTs per page (default)",
    value: "12",
  },
  {
    label: "24 NFTs per page",
    value: "24",
  },
  {
    label: "48 NFTs per page",
    value: "48",
  }
];

  

const handleChange = (value: string) => {
  setMyTokenId([]);
  //setValueIndex(dropDownData.findIndex((d) => d.value === value));
  setValue(value);
  setPageAmount(Math.ceil(myTokenAmount/Number(value)));
  setPage(1);
  setJ(0);
  console.log("Dropdown value: ", value);
};
async function sendToken(address_: string | undefined, tokenId_: number | undefined)  {

  if (signerConnected === true && account !== null && tokenId_ !== undefined && address_ !== undefined) {
    console.log('From SendToken: Input params: ', address_, tokenId_);
    setLoader(true);
    try {
      const newProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = newProvider.getSigner();
      const newContract = contract.connect(signer);
      //if (tokenId_ != undefined && ) {
  
        console.log('From SendToken: token`s position is ',tokenId_);
        const responseTokenId = await newContract.tokenOfOwnerByIndex(account,tokenId_);
        console.log('From SendToken: tokenId is ', Number(responseTokenId._hex), 'owned by ', account);
        //(address_).toLowerCase() (account).toLowerCase()
          const gasLimit_ = await newContract.estimateGas["safeTransferFrom(address,address,uint256)"]((account).toLowerCase(),(address_).toLowerCase(),BigInt(Number(responseTokenId._hex)));
          console.log('Estimate gas: ', Number(gasLimit_._hex));
          console.log('From SendToken: First param is ', (account).toLowerCase());
          console.log('From SendToken: Second param is ', (address_).toLowerCase());
          console.log('From SendToken: Third param is ', Number(responseTokenId._hex));
          const newGasLimit_ = Math.ceil(1.2*(Number(gasLimit_._hex)));
          console.log ('Increased Gas: ', newGasLimit_);
          console.log('BigNumber', BigNumber.from(newGasLimit_));
          //console.log('BigInt ',  BigInt(newGasLimit_));
          const safeTransfer = await newContract["safeTransferFrom(address,address,uint256)"]((account).toLowerCase(),(address_).toLowerCase(),Number(responseTokenId._hex), 
          {
            gasLimit: BigNumber.from(newGasLimit_), //работает вместе с gasPrice 11 !
            //gasLimit: BigInt(newGasLimit_), // переводит но выдает сообщение с ошибкой
            gasPrice: ethers.utils.parseUnits((12).toString(),"gwei"),
          }); 
          const response = await safeTransfer.wait(1);
          console.log('Response is:', response.transactionHash);
          setTxResponse((prev)=>(prev = "TX: " + response.transactionHash));
          showNotification({
            id: 'SafeTransfer',
            title: `Token #${Number(responseTokenId._hex)} transfered`,
            message: `TX:${response.transactionHash}`,
            color: "green",
            icon: <FiCheckCircle size={16} />,
            //autoClose: 4000,
          });
          console.log('Token #',String(responseTokenId),' is transfered to ', address_);   
        

        //}    
      // }
    } catch (error: any) {
      console.log("Error code: ", error.code);
      console.log("Error reason: ", error.reason);
      console.log("Error message: ", error.message);
      setLoader((prev) => (prev = false));
      //было error.code
     setErrorMessage("Tranfer failed. Reason: " + error.reason);
    } finally {
      setLoader((prev) => (prev = false));
      const myTokens_ = await contract.balanceOf(String(account));
      console.log("New token amount of", account, "is", Number(myTokens_));
      setMyTokenAmount(Number(myTokens_));
      resetState();
      
    }
  }
}

function WrongNetwork() {
  return (
    <Button onClick={() => switchChain(correctChainId)}
            size="xl" 
            title="Press to connect Metamask">
              Switch to CryptoEmergency
    </Button>
  )
}
function MetaMaskStatus() {
    if (status === "initializing") return <Text>Synchronisation with MetaMask ongoing...</Text>
  
    if (status === "unavailable") return <Text>You need to install MetaMask</Text>
  
    if (status === "notConnected")
    return <Button 
              size="xl" 
              onClick={connect}
              title="Press to connect Metamask">
                Connect with MetaMask
            </Button>;
    if (status === "connecting") return <Text>Connecting...</Text>
  
    if (status === "connected") return <Text>Connected account {shortAccount} on chain ID {Number(chainId)}</Text>
    return null;
}     

const mitems = myTokenIds.map((mytokenid) => {

    return (
      <> 
        <Grid.Col style={{
              //minWidth: 400, 
              maxWidth: 520,
              padding: "5px",
              textAlign: "center", 
              justifyContent: "space-around"
              }} sm={6} lg={4} xl={3} key={`Item_${mytokenid.id}`}>
          <Text key={`Button_${mytokenid.name}`}>{mytokenid.name}</Text>
          <Image  radius="md"
                  alt="NFT image"
                  src={mytokenid.image}   
                  title={mytokenid.name}/>
           <Button key={`Button_${mytokenid.id}`} style={{ marginTop: 5, marginBottom: 2 }} onClick={() => {handlers.open(); setModalContent(mytokenid);}}>Detail {mytokenid.name}</Button>
          <Modal
            opened={openedModal}
            onClose={() => handlers.close()}
            size="md"
            title="NFT Details"
            key={`Modal_${mytokenid.id}`}
            overlayProps={{opacity: 0.1}}
            transitionProps={{duration:100,  transition: "skew-up"}}
          >
              {<>
                <Flex 
                mih={15}
                gap="xs"
                justify="flex-end"
                align="center"
                direction="row"
                wrap="nowrap">
              <Text 
                  fz={13}
                  ta="right"
                  style={{ flexGrow: 1, paddingRight: 23 }} 
                  className={classes.boldFont}>{modalContent?.description}</Text>
              {modalContent?.image && <CopyButton value={modalContent.image} timeout={1000}>
                {({ copied, copy }) => (<Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
                {copied ? <IconCheck size={16} /> : <IconCopy size={16} />}
                </ActionIcon></Tooltip> )}</CopyButton>}
            </Flex>
              
                  <Image src={modalContent?.image} alt="NFT image" title={modalContent?.name}/>
                  <Table highlightOnHover={true} horizontalSpacing="md" verticalSpacing="xs" striped={true} withBorder={true} withColumnBorders={true} >
                  <tbody>
                    <tr key="name">
                      <td>Name: </td>
                      <td className={classes.boldFont}>{ modalContent?.name }</td>
                    </tr>
                    <tr key="feature">
                      <td>Feature: </td>
                      <td className={classes.boldFont}>{modalContent?.feature}</td>
                    </tr>
                    <tr key="degree">
                      <td>Degree: </td>
                      <td className={classes.boldFont}>{modalContent?.degree}</td>
                    </tr>
                    <tr key="angle">
                      <td>Angle: </td>
                      <td className={classes.boldFont}>{modalContent?.angle}</td>
                    </tr>
                    <tr key="firstColor">
                      <td>1st gradient color: </td>
                      <td className={classes.boldFont}>{modalContent?.gColor1}</td>
                    </tr>
                    <tr key="secondColor">
                      <td>2nd gradient color: </td>
                      <td className={classes.boldFont}>{modalContent?.gColor2}</td>                    
                    </tr>
                    <tr key="creationDate">
                      <td>Creation date: </td>
                      <td className={classes.boldFont}><Moment unix>{modalContent?.created}</Moment></td>
                    </tr>
                    </tbody>
                  </Table>
                 
                  <form 
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}>
                    <TextInput fz={10} 
                                ta="center" 
                                label="Address field" 
                                placeholder="Input destination address here..." 
                                ref={addressRef}
                                c={errorMessage !== "" ? "red" : undefined}
                                // style={{
                                //   color: errorMessage !== "" ? "red" : undefined,
                                // }}
                                />
                    <Button 
                      key={`Send_Button_${mytokenid.id}`} 
                      style={{ marginTop: 5, marginBottom: 2, alignSelf: "center"}} 
                      onClick={() => {
                                      if (addressRef.current?.value) {
                                        if (!ethers.utils.isAddress((addressRef.current?.value).toLowerCase())) { 
                                          console.log('Incorrect input address');
                                          setErrorMessage('Incorrect destination address');
                                        return;}
                                        else {
                                          console.log('Token #',modalContent?.id);
                                          console.log('Destination ', addressRef.current?.value );
                                          sendToken(addressRef.current?.value, modalContent?.id); 
                                          handlers.close();
                                       }
                                     }
                                      }}>
                        Send {modalContent?.name}
                    </Button>
                 
                  </form>
                  
              </>}
  
          </Modal>
        </Grid.Col>
      </>  
    ); 
  });

return (
  <>
   
    <Box >
      {status !== "connected" && <MetaMaskStatus />}
      
      {(status === "connected") && 
        <>
        
         {isCorrectChain === false &&
          <Box title="Chain data" className={cx(classes.dataBox, classes.dataBoxPadding)}>
              
              <Text  className={classes.textMargin}>My account : {account}</Text>
                <Divider className={classes.divider} />

              <Text className={classes.textMargin} color={isCorrectChain ? "Default" : "red"}>ChainID: {Number(chainId)}</Text>
                <Divider className={classes.divider} />
            </Box>
         }
          { isCorrectChain === true && 
          <>
           
          <Box title="Token amount info" className={cx(classes.dataBox, classes.dataBoxPadding)}>
            <Text className={classes.textMargin}>Minted token supply: {minted} / {maxTokenAmount}</Text>
              <Divider className={classes.divider} />
            <Text className={classes.textMargin}>My NFT amount: {myTokenAmount} </Text>   
              <Divider className={classes.divider} />
          <Box className={classes.refreshBox}>
            <ActionIcon
                  className={classes.refresh}
                  variant="outline"
                  size={42}
                  title="Refresh tokens"
                  onClick={resetState}
                >
              {<IconRefresh size={24}/>}
            </ActionIcon>         
            <Select
              className={classes.refreshBoxSelect}
              title="Drop down select menu"
              styles={(theme) => ({
                item: {
                  '&[data-selected]': {
                    '&, &:hover': {
                      backgroundColor:
                        theme.colorScheme === 'dark' ? theme.colors.gray[9] : theme.colors.gray[2],
                      color: theme.colorScheme === 'dark' ? theme.white : theme.colors.gray[9],
                    },
                  },
                  // applies styles to hovered item (with mouse or keyboard)
                  '&[data-hovered]': {},
                },
                }
                )}

                label="How many NFTs to be displayed?"
                placeholder="Pick amount to be displayed"
                data={dropDownData}
                value={value}
                onChange={handleChange}
                maxDropdownHeight={400}
                //defaultValue="12"
                zIndex={10}
                filter={(value, item) => item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
                  item.description.toLowerCase().includes(value.toLowerCase().trim())} />
            </Box>
          </Box>
          <Grid styles={{minWidth:300}} justify={"space-around"}>
            {myTokenIds && <>{mitems}</>}
          </Grid>

        <Pagination 
          style={{ marginTop: 12, }} 
          position="center" siblings={2} 
          value={activePage} 
          onChange={(page)=>(setPage(page))} 
          total={pageAmount} 
          color="violet" 
          size="lg" 
          radius="md" 
          withEdges />
          </>   
          }

        {isCorrectChain === false  && 
            <>
              <Box  className={classes.chainBtnBox}>
                  <WrongNetwork /> 
              </Box>
            </>
        } 
        
    
      
    </>}
   </Box>
  </>
  );
};
export default MyNFTsGrid;
// {tokenids.length? <>{mitems}</> : <Text>You do not own any NFTs</Text>}
//
        // <Button style={{ margin: 5 }} key={"h20"} onClick={getMintedAmount}>Get MyMintedAmount</Button>
      //  <Button style={{ margin: 5 }} key={"h111"} onClick={getMyDatas}>Get Datas</Button>

  

// <Button 
// style={{ margin: 5 }} 
// key={"h79"} 
// onClick={()=>(console.log("tokenids.length : ", tokenids.length))}>Get Length</Button>
        // <Button 
        //   style={{ margin: 5 }} 
        //   key={"h7"} 
        //   onClick={()=>(console.log("Amount NFT per page is", Number(value)))}>Get NFT per page</Button>
        // <Button 
        //   style={{ margin: 5 }} 
        //   key={"h8"} 
        //   onClick={()=>(console.log("Current index: ", i))}>Get Index</Button>
        // <Button 
        //   style={{ margin: 5 }} 
        //   key={"h79"} 
        //   onClick={()=>(console.log("tokenids.length : ", myTokenIds.length))}>Get Length</Button>
        //юзэффект который устанавливает I к 0 если баланс токенов владельца больше 0, переустанавливается всегд когда myTokenAmount
// useEffect(() => {
//   (async () => {
//    console.log("MyTokenAmount useEffect started");
//     if (myTokenAmount > 0) {
//        setI(0);
//        setPageAmount(Math.ceil(myTokenAmount/Number(value)));
//     console.log("i =", i);
//     }
//  })
//  ();
// }, [myTokenAmount]);
  

// <Button 
//         style={{ margin: 5}} 
//         key={"h6"} 
//         onClick={()=>(console.log("Active Page is",activePage))}>Get Current page</Button> 
//       <Button 
//         style={{ margin: 5 }} 
//         key={"h66"} 
//         onClick={()=>(console.log("Current value is:",value))}>Get Value</Button>      
//       <Button 
//         style={{ margin: 5 }} 
//         key={"h67"} 
//         onClick={()=>(console.log("index value is:",j))}>Get i</Button>  
//       <Button 
//          style={{ margin: 5 }} 
//         key={"h10"} 
//         onClick={()=>(setJ((prev) => prev + 1))}> i ++</Button>      
        // <Group  style={{display: "flex", justifyContent: "space-between"}}  >
        //       <Text 
        //         fz={13}
        //         style={{marginLeft: 80}} 
        //         className={classes.boldFont}>{modalContent?.description}</Text>
        //       {modalContent?.image && <CopyButton value={modalContent.image} timeout={1000}>
        //       {({ copied, copy }) => (<Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
        //       <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
        //       {copied ? <IconCheck size={16} /> : <IconCopy size={16} />}
        //       </ActionIcon></Tooltip> )}</CopyButton>}
        //       </Group>


