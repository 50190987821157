import React from 'react';
import { Global } from '@mantine/core';
import Light from "./../fonts/Montserrat-Light.woff2";
import Regular from "./../fonts/Montserrat-Regular.woff2";
import SemiBold from "./../fonts/Montserrat-SemiBold.woff2";
import Unbounded_Regular from "./../fonts/Unbounded-Regular.woff2";
import Unbounded_Medium from "./../fonts/Unbounded-Medium.woff2";
import Unbounded_Light from "./../fonts/Unbounded-Light.woff2";
import Tahoma from "./../fonts/Tahoma/Tahoma.woff2";
import TahomaBold from "./../fonts/Tahoma/Tahoma-Bold.woff2";
function MyGlobalStyles() {
  return (
    <Global
    styles={[
        {
          '@font-face': {
            fontFamily: 'Montserrat',
            src: `url('${Regular}') format("woff2")`,
          },
        },
        {
          '@font-face': {
            fontFamily: 'Montserrat-Light',
            src: `url('${Light}') format("woff2")`,
          },
        },
        {
          '@font-face': {
            fontFamily: 'Montserrat-SemiBold',
            src: `url('${SemiBold}') format("woff2")`,
          },
        },
        {
          '@font-face': {
            fontFamily: 'Unbounded-Regular',
            src: `url('${Unbounded_Regular}') format("woff2")`,
          },
        },
        {
          '@font-face': {
            fontFamily: 'Unbounded-Medium',
            src: `url('${Unbounded_Medium}') format("woff2")`,
          },
        },
        {
          '@font-face': {
            fontFamily: 'Unbounded-Light',
            src: `url('${Unbounded_Light}') format("woff2")`,
          },
        },
        {
          '@font-face': {
            fontFamily: 'TahomaBold',
            src: `url('${TahomaBold}') format("woff2")`,
          },
        },
        {
        '@font-face': {
          fontFamily: 'Tahoma',
          src: `url(${Tahoma}) format('truetype')`
        },
      }
      ]}
    />
  );
}
export default MyGlobalStyles;