import * as React from "react";
import { Outlet } from "react-router-dom";
import CustomFooter from "./CustomFooter";
//import HeaderMiddle from "./HeaderMiddle";
import { createStyles, Box } from "@mantine/core";
import HeaderResponsive from "./HeaderResponsive";

const useStyles = createStyles((theme) => ({
  pageContainer: {
    minHeight: "100vh",
    flexDirection: "column",
    display: "flex",
    backgroundColor:
    theme.colorScheme === "dark"
      ? theme.colors.dark[3] //dark[3]
      : theme.colors.gray[0],//3 Тоже не плохо!
    alignItems: "center",
    // maxWidth: "1000px",
    width: "100vw",
    flexWrap: "wrap",

    //boxSizing: "content-box",
    //     // [theme.fn.smallerThan("sm")]: {
    //     //   justifyContent: "flex-start",
    //     // },
  },
  main: {
    flexGrow: 1,
    backgroundColor: "none",
    //height: "100vh",
    //minHeight: "100vh",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    flexdirection: "column",
    maxWidth: "1200px",
    width: "100vw",
    padding: "5px",
  },
}));

const Layout = () => {
  const { classes } = useStyles();
  return (
    <>
      <Box className={classes.pageContainer}>
        <HeaderResponsive
          links={[
            { link: "/", label: "Main", title: "To Main page" },
            { link: "/mynfts", label: "MyNFTs", title: "To MyNFTs page" },
            { link: "/nfts", label: "NFTs", title: "To NFTs page" },
            //{ link: "/nfts2", label: "NFTs 2", title: "To NFTs 2 page" },
            { link: "/about", label: "About", title: "To About page" },
          ]}
        />
          <section className={classes.main}>
            <Outlet />
          </section>
        <CustomFooter />
      </Box>
    </>
  );
};

export default Layout;
