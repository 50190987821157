import React from "react";
import DarkLightButton from "./DarkLightButton";
import {
  createStyles,
  Group,
  ActionIcon,
  Text,
  Box,

} from "@mantine/core";
import { RiMailSendLine, RiTwitterLine, RiDiscordLine } from "react-icons/ri";

const useStyles = createStyles((theme) => ({
  footerStyle: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "nowrap",
    backgroundColor:
    theme.colorScheme === "dark"
      ? theme.colors.dark[4]
      : theme.colors.gray[3],
    flexDirection: "row",
    alignItems: "center",
    width:"100vw",
    maxWidth: "1200px",
    height: "50px",
    [theme.fn.smallerThan(1200)]: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      },
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,

  },
  siteData: {
    marginRight: "5vw",
    justifyContent: "flex-end",
    marginLeft: "auto",
    [theme.fn.smallerThan(1200)]: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      },
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  extLinks: {
    marginLeft: "5vw",
    justifyContent: "flex-start",
    //padding: "0 20px",
    flexWrap: "nowrap",
    [theme.fn.smallerThan(550)]: {
      display: 'none',
      },

  },
  links:{    
    color:
    theme.colorScheme === "dark"
      ? theme.colors.dark[0]
      : theme.colors.gray[7],
  "&:hover": {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
  },
  },
}));

const CustomFooter: React.FC = () => {
  const today = new Date();
  const { classes } = useStyles();
//height={50}
  return (
    <Box  className={classes.footerStyle}>
      <Group className={classes.extLinks}>
          <ActionIcon
            className={classes.links}
            variant="outline"
            component="a"
            size={35}
            title="Twitter page"
            href="https://twitter.com/NftEmergency"
            target={"_blank"}
          >
            {<RiTwitterLine size={22}/>}
          </ActionIcon>
          <ActionIcon
            size={35}
            className={classes.links}
            variant="outline"
            component="a"
            title="Discord page"
            href="https://discord.com"
            target={"_blank"}
          >
            {<RiDiscordLine size={22}/>}
          </ActionIcon>
          <ActionIcon
            className={classes.links}
            size={35}
            variant="outline"
            component="a"
            title="E-mail link"
            href="mailto:support@nft-emergency.xyz"
            target={"_blank"}
          >
            {<RiMailSendLine size={22} />}
          </ActionIcon>
      </Group>
      <Group className={classes.siteData}>
        
        <Text> &#169; NFT-Emergency {today.getFullYear()}</Text>
        <DarkLightButton />
      </Group>
     
    </Box>
  );
};
export default CustomFooter;
