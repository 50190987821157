import React, { useState, useEffect, memo } from "react";
import { BigNumber, ethers } from "ethers";
import { useMetaMask } from "metamask-react";
import contract from "../components/BrowserProviderOnly";
import { Button, createStyles, Text, Box, Divider, Group} from "@mantine/core";
import { showNotification, updateNotification } from "@mantine/notifications";
import { FiCheckCircle,FiXCircle} from "react-icons/fi";

const useStyles = createStyles((theme) => ({

  mainBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    //flexGrow: 1,
    backgroundColor: "none", //
    padding: "5px",
    alignSelf: "center",
    
  },
  mintBox: {
    margin: "0px auto",
    backgroundColor: "none",
    alignItems: "center",
    padding: theme.spacing.sm,
    alignSelf: "center",
    justifyContent: "center",
    //boxShadow: "5px 5px 10px 1px lightgray",
    //marginBottom: "auto",
    //maxHeight: "100vh",   
    //backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    //color: theme.colorScheme === 'dark' ? theme.colors.blue[4] : theme.colors.blue[7],
  },
  dataBox: {
    fontSize: 15,
    // lineHeight: 32,
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[5]
        : theme.colors.gray[3],
    color:
      theme.colorScheme === "dark"
        ? theme.colors.gray[3]//theme.colors.indigo[4]
        : theme.colors.dark[6],
    // padding: theme.spacing.xl,
    marginBottom: "10px",
    borderRadius: theme.radius.md,
    //boxShadow: "5px 5px 2px 0px lightgray",
    minWidth: "500px",
    alignSelf: "center",
  },
  dataBoxPadding: {
    padding: theme.spacing.lg,
  },
  chainBtnBox: {
    display: "flex",
    justifyContent: "center",
    margin: 10,
  },
  amountBtn: {
    minHeight: "80px",
    fontSize: 50,
    fontWeight: 600,
    flexGrow: 1,
    backgroundColor: 
        theme.colorScheme === 'dark' 
          ? theme.colors.dark[7] 
          : theme.colors.gray[6],
    color:  theme.colorScheme === 'dark' 
          ? theme.colors.dark[0] 
          : theme.colors.gray[0],
    '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[6],
      }, 
  },
  btnDisabled: {
    cursorType: "not-allowed",
  },

  mintBtn: {
    minHeight: "80px",
    fontSize: 36,
    fontWeight: 600,
    flexGrow: 1,
    backgroundColor: "none",
    // backgroundColor:
    //   theme.colorScheme === "dark"
    //     ? theme.colors.dark[5]
    //     : theme.colors.gray[3], // общее свойство
    //color: theme.colorScheme === 'dark' ? theme.colors.indigo[4] : theme.colors.dark[6],// общее свойство
    //padding: theme.spacing.xl,
    borderRadius: theme.radius.md,
   // boxShadow: "5px 5px 2px 0px lightgray",
    minWidth: "400px",
    display: "flex",
  },
  tokenBox:{
    display: "flex",
    flexWrap: "wrap",
    flex: "1 1 100px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",    
  },
  divider: {
    borderColor: theme.colors.gray[6],
    borderRadius:"1px",
    borderWidth: "0.8px",
  },
  textMargin:{
    margin: "6px auto", 
    fontSize: "clamp(12px,1.5vw,20px)",
  },

}));

const MainMint: React.FC = () => {
  const { classes, cx } = useStyles();

  const { status, connect, account, chainId, ethereum, switchChain, addChain } = useMetaMask();
  const shortAccount =
    String(account).substring(0, 5) +
    "..." +
    String(account).substring(
      String(account).length - 5,
      String(account).length
    );

  //account data
  const [balance, setBalance] = useState<number>(0); //useState1

  //contract  constants

  const [mintLimit, setMintLimit] = useState<number>(0);
  const [maxSupply, setMaxSupply] = useState<number>(0);

  //contract state data
  const [minted, setMinted] = useState<number>(0);
  const [myMintedTokenAmount, setMyMintedTokenAmount] = useState<number>(0);
  const [paused, setPaused] = useState<boolean>(false);
  //const [charityPayed, setCharityPayed] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [signerConnected, setSignerConnected] = useState<boolean>(false);
  const [isOwnerConnected, setOwnerConnected] = useState<boolean>(false);
   

  //internal
  const [txResponse, setTxResponse] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [price, setTokenPrice] = useState<number>(0);
  const [readyMint, setReadyMint] = useState<boolean>(false);
  const [tokenAmount, setTokenAmount] = useState<number>(1);

  const [isCorrectChain, setCorrectChain] = useState<boolean>(false);
  const correctChainId = "0xc1"

  const setDecrement = () => {
    if (tokenAmount <= 1) return;
    setTokenAmount((prev) => prev - 1);
  };
  const setIncrement = () => {
    if (tokenAmount >= mintLimit) return;
    setTokenAmount((prev) => prev + 1);
  };

  //HOOKS
  //hook checks if chain is correct and sets bool state
  useEffect(() => {
    if (chainId !== correctChainId) {
      setCorrectChain(false);
      console.log('Chain is wrong');
    }
    if (chainId === correctChainId) {
      setCorrectChain(true);
      console.log('Chain is correct');
    }
  }, [chainId]); 

  //account balance + contract connect + owner check hook
  useEffect(() => {
    (async () => {
      if (status === "connected") {
        setLoader(true);
        try {
          ethereum
            .request({
              method: "eth_getBalance",
              params: [account, "latest"],
              id: chainId,
            })
            .then((balance: any) => {
              setBalance(Number(ethers.utils.formatEther(balance)));
            });
          const connectAcc = contract.connect(String(account));
          
          await connectAcc.signer.getAddress().then((signer_: string) => {
            console.log(
              "This address:",
              signer_,
              "is signer = ",
              connectAcc.signer._isSigner
            );
            setSignerConnected(connectAcc.signer._isSigner);

            setOwnerConnected(
              (account).toLowerCase() === (`${process.env.REACT_APP_OWNER_ADDRESS}`).toLowerCase() 
            );
            console.log(
              "Owner connected: ",
              (account).toLowerCase() ===  (`${process.env.REACT_APP_OWNER_ADDRESS}`).toLowerCase()
            );
          });
          const p = await contract.paused();
          setPaused(p);
          console.log("Contract is ", p? "paused" : "live");
          const currentTokenSupply_ = await contract.mintSupply();
          console.log("Totally tokens minted:", Number(currentTokenSupply_));
          setMinted(Number(currentTokenSupply_));
          const myMintedTokens_ = await contract.tokensMinted(String(account)); 
          console.log("Tokens minted for", account, "is", Number(myMintedTokens_));
          setMyMintedTokenAmount(Number(myMintedTokens_));
        } catch (error: any) {
          console.log("Error code:", error.code);
          console.log(error.reason);
          console.log(error.message);
          setErrorMessage("Data request failed. Code: " + error.code);
        } finally {
          setLoader(false);
          console.log("Loader is: ", loader);
        }
      }
    })();
  }, [status, account, chainId, txResponse]);

  //contract constants read hook
  useEffect(() => {
    (async () => {
      if (status === "connected") {
        // check contract connection
        try {
          setMintLimit(Number(await contract.NFT_MINT_LIMIT()));
          setMaxSupply(Number(await contract.MAX_NFT_SUPPLY()));
          setTokenPrice(Number(await contract.tokenPrice()));
          setTokenAmount(1);
        } catch (error: any) {
          console.log("Error code: ", error.code);
          console.log("Error reason: ", error.reason);
          console.log("Error message: ", error.message);
          setErrorMessage("Constants request failed. Code: " + error.code);
        }
      }
    })();
  }, [account, status]);

  //enough balance
  useEffect(() => {
    if (balance > (tokenAmount * price) / 10 ** 18) {
      console.log(
        balance,
        tokenAmount * price,
        balance > (tokenAmount * price) / 10 ** 18
      );
      setReadyMint(true);
      return;
    }
    setReadyMint(false);
    console.log(
      balance,
      tokenAmount * price,
      balance > (tokenAmount * price) / 10 ** 18
    );
  }, [tokenAmount, balance, price]);

  //useeffect for loader
useEffect(() => {
  if(loader === true){
    showNotification({
      id:'Load',
      loading: true,
      title: 'Loading data',
      message: 'Please wait a few seconds...',
     // withCloseButton: false,
      //autoClose: 3000,
  });
}
if(loader === false){
  updateNotification({
    id: 'Load',
    title: 'Loading is complete!',
    message: txResponse==="" ? 'Data loaded': txResponse,
    color: "green",
    icon: <FiCheckCircle size={16} />,
    onClose: ()=>(setTxResponse("")),
  });
}

}, [loader]);

//useeff for Error Message
useEffect(() => {
  if(errorMessage!==""){
    showNotification({
      id:'Error',
      title: 'Error',
      message: errorMessage,
      color: "red",
      icon: <FiXCircle size={16} />,
      onClose: ()=>(setErrorMessage("")),
  });
}
}, [errorMessage]);
  
//token minting request function

async function handleMint() {
    if (status === "connected") {
      setLoader(true);
      try {
        const newprovider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = newprovider.getSigner();
        const newcontract = contract.connect(signer);
        const price_ = await newcontract.tokenPrice();
        const totalValueTranfered_ = tokenAmount * Number(price_);
        const gasLimit_ = await newcontract.estimateGas.mintAmount(BigInt(tokenAmount), {
         // value: ethers.utils.parseUnits((tokenAmount * Number(price_)).toString(),"wei"), //было так
          value: BigInt(totalValueTranfered_),
        });
        const newGasLimit_ = Math.ceil(1.3*(Number(gasLimit_._hex)));
          console.log ('Increased Gas: ', newGasLimit_);
          console.log('BigNumber', BigNumber.from(newGasLimit_));
          console.log('BigInt ',  BigInt(newGasLimit_));
        const interact_ = await newcontract.mintAmount(BigInt(tokenAmount), 
        {
          //value: ethers.utils.parseUnits((tokenAmount * Number(price_)).toString(),"wei"),
          //gasLimit: (Number(gasLimit_) + 200000).toString(), //было
          value: BigInt(totalValueTranfered_),
          gasLimit: BigInt(newGasLimit_),
          gasPrice: ethers.utils.parseUnits((11).toString(),"gwei"),
        }
        );
        console.log(price_, "interaction: ", interact_);
        const response = await interact_.wait();
        showNotification({
          id: 'Minted',
          title: tokenAmount > 1 ? `Minted ${tokenAmount} tokens`: `Minted ${tokenAmount} token`,
          message: `For: ${account}`,
          color: "green",
          icon: <FiCheckCircle size={16} />,
          //autoClose: 4000,
        });
        setTxResponse("TX:"+response.transactionHash);
        console.log("TX hash: " + response.transactionHash);
        setTokenAmount(1);
      } catch (error: any) {
        console.log("Error code: ", error.code);
        console.log("Error reason: ", error.reason);
        console.log("Error message: ", error.message);
        setErrorMessage("Mint failed. Reason: " + error.reason);
      } finally {
        setLoader((prev) => (prev = false));
      }
    }
  }

  async function payCharity() {
    if (isOwnerConnected === true) {
      setLoader(true);
      try {
   
        const newprovider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = newprovider.getSigner();
        const newcontract = contract.connect(signer);
        console.log("Charity address:", process.env.REACT_APP_CHARITY_ADDRESS);
        const charityInteract_ = await newcontract.fundCharity((`${process.env.REACT_APP_CHARITY_ADDRESS}`).toLowerCase());
        const response = await charityInteract_.wait();
        setTxResponse("TX: " + response.transactionHash);
        console.log("TX hash: " + response.transactionHash);
      } catch (error: any) {
        console.error(error.code);
        console.log("Error reason: ", error.reason);
        console.log("Error message: ", error.message);
        setErrorMessage("Charity payment error: " + error.code);
      }
      finally{
        setLoader((prev) => (prev = false));
      }
    }
  };

  async function payOwner() {
    if (isOwnerConnected === true ) {
      setLoader(true);
      try {
        const newprovider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = newprovider.getSigner();
        const newcontract = contract.connect(signer);
        const payowner_ = await newcontract.withdraw();
        const response = await payowner_.wait();
        setTxResponse("TX: " + response.transactionHash);
        console.log("TX hash: " + response.transactionHash);
      } catch (error:any) {
        console.error(error);
        setErrorMessage("Owner payment error: " + error.reason);
      }
      finally{
        setLoader(false);
      }
    }
  };
  async function toglePause() {
    if (signerConnected === true) {
      setLoader(true);
      try {
        const newprovider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = newprovider.getSigner();
        const newcontract = contract.connect(signer);
        const interact_ = await newcontract.togglePause(!paused);
        const response = await interact_.wait();
        setTxResponse("TX: " + response.transactionHash);
        setPaused(!paused);
      } catch (error: any) {
        console.log("Error code:", error.code);
        console.log(error.reason);
        console.log(error.message);
        setErrorMessage(
          "Pause togle failed. Code: " + error.code
        );
      } finally {
        setLoader(false);
      }
    }
  }
  //function for network change!!
 async function WrongNetwork() {
  const customChainNetworkParams = {
      chainId: "0xc1",
      chainName: "CryptoEmergency",
      rpcUrls: ["https://cemchain.com"],
      nativeCurrency: {
        name: "CEM",
        symbol: "CEM",
        decimals: 18,
      },
      blockExplorerUrls: ["https://cemscan.com/"]
    };
    if (chainId !== correctChainId){
      setLoader(true);
      try {
        await switchChain(correctChainId);
      } catch (error: any) {
        if (error.code === 4902) {
          try {
            await addChain(customChainNetworkParams);
          } catch (error: any) {
            setErrorMessage("Network error: " + error.code);
          }
        }
      }
      finally{
        setLoader(false);
      }
    }
  
  }
 
  function MetaMaskStatus() {
    if (status === "initializing")
      return <Text>Synchronisation with MetaMask ongoing...</Text>;

    if (status === "unavailable") return <Text>MetaMask not available..</Text>;

    if (status === "notConnected")
      return <Button 
                size="xl" 
                onClick={connect}
                title="Press to connect Metamask"
                // variant="white"
                // sx={(theme) => ({
                //   backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[7],
                //   color:  theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[0],
                //   '&:hover': {
                //     backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[6],
                //    // color: theme.colors.cyan[2],
                //   },
                // })}
                >Connect with MetaMask</Button>;

    if (status === "connecting") return <Text>Connecting...</Text>;

    if (status === "connected")
      return (
        <Text>
          Connected account {shortAccount} on chain ID {Number(chainId)}
        </Text>
      );
    return null;
  }


  return (
    <Box className={classes.mainBox}>
      <Text ta="center" fw={700}  className={classes.textMargin}>Welcome to your FaithToken minting page</Text>

      {status !== "connected" && <MetaMaskStatus />}

      {status === "connected" && (
        <>
          <Box className={classes.mintBox}
    
          >
            <Box title="Chain data" className={cx(classes.dataBox, classes.dataBoxPadding)}>
              
              <Text className={classes.textMargin}>Your account: {account}</Text>
                <Divider className={classes.divider} />
              <Text className={classes.textMargin}>Contract address: {process.env.REACT_APP_CONTRACT_ADDRESS}</Text>
                <Divider className={classes.divider} />
              <Text className={classes.textMargin} color={isCorrectChain ? undefined : "red"}>Chain ID: {Number(chainId)}</Text>
                <Divider className={classes.divider} />
              {isCorrectChain === true && (
              <>
              <Box style={{
                display: "flex",
                flexWrap: "nowrap",
                justifyContent: "stretch",
                flexDirection: "row",
                padding: "5px",
                }}
              >
                <Box className={classes.tokenBox}>
                  <Text className={classes.textMargin}>
                    {paused ? "Mint function is paused" : "Mint function is live"}
                  </Text >
                </Box>
                <Divider className={classes.divider} orientation="vertical" />
                <Box className={classes.tokenBox}>
          
              
                  <Text className={classes.textMargin}
                        color={minted!==maxSupply ? undefined : "red"}>
                    {minted===maxSupply ? "Minting is over" : "Minting in progress"}
                  </Text>
              
                </Box>
              </Box>
              <Divider className={classes.divider} />
              </>)}
            </Box>
            {isCorrectChain === false  && 
              <>
                <Box className={classes.chainBtnBox}>
                  <Button onClick={WrongNetwork}
                        size="xl" 
                        title="Press to connect Metamask">
                        Switch to CryptoEmergency
                  </Button>
                </Box>
              </>
            } 
            {isCorrectChain === true && (
            <>
            <Box title="Token data" className={cx(classes.dataBox, classes.dataBoxPadding)}>
              <Text className={classes.textMargin}> Current supply: {Number(minted)} / {maxSupply}</Text>
                <Divider className={classes.divider} />
              <Text className={classes.textMargin}>Token price: {price/(10 ** 18)} CEM</Text>
                <Divider className={classes.divider} />
              <Text className={classes.textMargin}
              color={readyMint ? undefined : "red"}
              >
                {readyMint
                  ? "Enough coins to mint"
                  : "Not enough coins to mint"}
              </Text>
                <Divider className={classes.divider} />
              <Box style={{
                display: "flex",
                flexWrap: "nowrap",
                justifyContent: "stretch",
                flexDirection: "row",
                padding: "5px",
              }}
              >
              <Box className={classes.tokenBox}> 
                <Text className={classes.textMargin}>My minted tokens amount: </Text>
                <Text fz={"xl"} fw={600} color={Number(myMintedTokenAmount)===mintLimit ? "red" : undefined }>{Number(myMintedTokenAmount)}</Text>
              </Box> 
              <Divider className={classes.divider} orientation="vertical" />
              <Box className={classes.tokenBox}>
                <Text className={classes.textMargin}>Total: </Text>
                <Group>
                <Text fz={"xl"} fw={600} >{(tokenAmount * Number(price)/(10 ** 18))} </Text><Text>CEM + Gas</Text>   
                </Group>        
              </Box>
              
              </Box>
              <Divider className={classes.divider} />
            </Box>

          

            <Box
              className={classes.dataBox}
              ta="center"
              >
              <Button.Group
                style={{ flexGrow: "1", justifyItems: "stretch" }}
                orientation="horizontal"
              >
                <Button
                  className={classes.amountBtn}
                  title="Decrement token amount"
                  style={{
                    borderBottomLeftRadius: 8,
                    borderTopLeftRadius: 8,
                  }}
                  disabled={tokenAmount <= 1}
                  onClick={setDecrement}
                >
                  -
                </Button>
                <Text  title="Amount to mint" fz={50} fw={500} style={{  width: "200px", userSelect: "none" }}>
                  {tokenAmount}
                </Text>
                <Button  className={(tokenAmount >= mintLimit - myMintedTokenAmount) ? cx(classes.amountBtn,classes.btnDisabled) : classes.amountBtn }
                  title="Increment token amount"
                  style={{
                    borderBottomRightRadius: 8,
                    borderTopRightRadius: 8,
                  }}
                  disabled={tokenAmount >= mintLimit - myMintedTokenAmount || minted === maxSupply}
                  onClick={setIncrement}
                >
                  +
                </Button>
              </Button.Group>
            </Box>

            <Box
              className={classes.mintBtn}
              // sx={(theme) => ({
              //   backgroundColor: "none",
              //   // backgroundColor:
              //   //   theme.colorScheme === "dark"
              //   //     ? theme.colors.dark[5]
              //   //     : theme.colors.gray[3], // общее свойство
              //   //color: theme.colorScheme === 'dark' ? theme.colors.indigo[4] : theme.colors.dark[6],// общее свойство
              //   //padding: theme.spacing.xl,
              //   borderRadius: theme.radius.md,
              //   boxShadow: "5px 5px 2px 0px lightgray",
              //   minWidth: "400px",
              //   display: "flex",
              // })}
            >
              <Button
                size="xl"
                title="Mint tokens"
                variant="gradient"
                fz={38}
                fw={600}
                gradient={{ deg: 45, from: "grape.8", to: "yellow.3" }}
                sx={(theme) => ({
                  color:
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[0]
                      : "white", // общее свойство
                  textAlign: "center",
                  borderRadius: theme.radius.md,
                  flexGrow: 1,
                  minHeight: "80px",
                  '&[data-disabled]': { pointerEvents: 'all' }
                  // "&:hover": {
                  //   color: theme.colors.dark[4]
                  // },
                  // '&:disabled': {
                  //   cursor: paused ? "not-allowed" : "auto",
                  //   //loader || minted === maxSupply || myTokenAmount === mintLimit || paused
                  //   //myTokenAmount === mintLimit
                  // }

                })}
                //data-disabled={true}
                disabled={!readyMint || paused || loader || minted === maxSupply || myMintedTokenAmount === mintLimit }  
                onClick={handleMint}
              >
                Mint
              </Button>
            </Box>
            </>  
            )}
          </Box>
 
          {isOwnerConnected && (
            <Box>
              <Button
                variant="gradient"
                gradient={{ deg: 45, from: "violet", to: "gray" }}
                style={{ margin: 5 }}
                onClick={toglePause}
              >
                {paused ? "Unpause contract" : "Pause contract"}
              </Button>
              <Button
                variant="gradient"
                gradient={{ deg: -45, from: "pink", to: "orange" }}
                style={{ margin: 5 }}
                onClick={payCharity}
              >
                Pay Charity
              </Button>
              <Button
                variant="gradient"
                gradient={{ deg: 0, from: "blue", to: "lime" }}
                style={{ margin: 5 }}
                onClick={payOwner}
              >
                Pay Owner
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default MainMint;


