import React from 'react';
import { MantineProvider, ColorSchemeProvider, ColorScheme } from '@mantine/core';
import { useHotkeys, useLocalStorage } from '@mantine/hooks';
import { Route, Routes } from 'react-router-dom';
import About from './pages/about';
import Main from './pages/main';
import MyNFTs from './pages/mynfts';
import NFTs from './pages/nfts';
import NotFound from './pages/notfound';
import Layout from './components/Layout';
import { Notifications } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import MyGlobalStyles from './components/MyGlobalStyles';
import NFTs2 from './pages/nfts2';

export default function App() {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'mantine-color-scheme',
    defaultValue: 'light',
    getInitialValueInEffect: true,
  });

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));
  
  useHotkeys([['mod+J', () => toggleColorScheme()]]);

return (
  <>
  <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
    
    <MantineProvider 
            inherit
            theme={{
              fontSizes: {
                xs: '0.5rem',
                sm: '0.75rem',
                md: '0.9rem',
                lg: '1rem',
                xl: '1.2rem',
              },
       
            primaryShade: { light: 6, dark: 4 }, 
            colorScheme,
            components:{Modal:{ 
                                defaultProps: {
                                  centered: true,
                                  withCloseButton: true,}
                              },
                        Button: { 
                                defaultProps: (theme) => ({                               
                                  color:  theme.colorScheme === 'dark' ? 'dark.7' : 'gray.5',
                                  sx:{
                                    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[9], //gray[0]
                                    "&:hover":{
                                      //color: theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[6],
                                      color: theme.colorScheme === "dark"
                                      ? theme.colors.violet[3]
                                      : theme.colors.violet[8],
                                    },
                                  }
                                  //original below

                                  // color:  theme.colorScheme === 'dark' ? 'dark.6' : 'gray.3',
                                  // sx:{
                                  //   color: theme.colorScheme === "dark"
                                  //   ? theme.colors.dark[0]
                                  //   : theme.colors.gray[8],
                                  //   "&:hover":{
                                      // color: theme.colorScheme === "dark"
                                      // ? theme.colors.violet[3]
                                      // : theme.colors.violet[5],
                          
                                  //   },
                                  // }

                              }), },
                            Badge: {
                              defaultProps: (theme) => ({ 
                                sx:{
                                  color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[8],
                                  backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[5],
                                },
                              }),
                            },
                            // Select: {
                            //   defaultProps: (theme) => ({ 
                            //     backgroundColor: "violet",
                                
                            //     // item:{ 
                            //     //   '&[data-selected]': {
                                    
                            //     //       backgroundColor:
                            //     //         theme.colorScheme === 'dark' ? theme.colors.teal[9] : theme.colors.teal[1],
                            //     //       color: theme.colorScheme === 'dark' ? theme.white : theme.colors.teal[9],
                            //     //   },
                                
                            //     // },
                            //     // sx:{
                            //     //   color: "red",
                            //     //   backgroundColor: "violet",
                            //     // },
                            //   }),
                            // }, 
                              },
            fontFamily: 'Unbounded-Light,Tahoma' //Montserrat-Light,Unbounded-Light,
            }} withGlobalStyles withNormalizeCSS > 
        <MyGlobalStyles/>
        <ModalsProvider>
        <Notifications fz={5} limit={4} position="bottom-left" autoClose={10000} containerWidth={"480px"}/>
        <Routes>
          <Route path='/' element={<Layout/>}>
            <Route index element={<Main />}/>
            <Route path="mynfts" element={<MyNFTs />}/>
            <Route path="nfts" element={<NFTs />}/>
            <Route path="about" element={<About />}/>
            <Route path="*" element={<NotFound />}/>
          </Route>
        </Routes>
    
      </ModalsProvider>
    </MantineProvider>
  </ColorSchemeProvider>
    
  </>

  );
}
//  <Route path="nfts2" element={<NFTs2 />}/>
// /  <Notifications fz={6} limit={4} position="bottom-left" autoClose={10000}> 
//

//withNormalizeCSS

/* <Link href="/about" target="_blank">
      About
      </Link><br/>
      <Link href="/pages/about">
      About 2
      </Link>
      <br/>
      <Link href="https://mail.ru" target="_blank">Mail</Link>
      <NavLink component="a" href="/about" > Link</NavLink> */

// import React from 'react';
// import './App.css';

// function App() {
//   return (
//     <div className="App">

     
//     </div>
//   );
// }

// export default App;
