import React from 'react';
import MyNFTsGrid from '../components/MyNFTsGrid';
import {Box, Text} from "@mantine/core";


const MyNFTs: React.FC = () =>{
    return ( 
      <>
      <Box style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "none", //
        padding: "5px",
        alignSelf: "center",

      }}>
        <Text fz={16} fw={700} style={{ margin: "1vh auto" }}>This page shows NFTs you own</Text>
        <MyNFTsGrid/>
      </Box> 
      </>
     );
}
 
export default MyNFTs;


