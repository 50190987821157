import React from 'react';

import {
    createStyles,
    Image,
    Group,
    Text,
    Box,
    List,
    Divider,
    Anchor,
  } from "@mantine/core";


const useStyles = createStyles((theme) => ({
  dataBox: {
    //justifyContent: "space-around",
    fontSize: 16,
    //lineHeight: 32,
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[5]
        : theme.colors.gray[3],
    color:
      theme.colorScheme === "dark"
        ? theme.colors.gray[5]
        : theme.colors.dark[7],
    padding: theme.spacing.xl,
    marginBottom: "15px",
    borderRadius: theme.radius.md,
    //boxShadow: "5px 5px 2px 0px lightgray",
    //minWidth: "500px",
    maxWidth:"700px",
    width:"100vw",
    //flexGrow: 1,
    //flex: "1 1 100px",
    //alignSelf: "center",
  },
  textBox:{
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    // textAlign: "left",
    // justifyContent: "left",
    //alignItems: "left",
    //marginBottom: "10px",
  },
  divider: {
    borderColor: theme.colors.gray[6],
    borderRadius:"1px",
    borderWidth: "0.8px",
  },
  textMargin:{
    margin: "6px auto", 
    //marginBottom: "6px",
    //textAlign:"center", // - это выравнивание текста работает для всех 
  },
  fontClamp:{
    fontSize: "clamp(11px,1vw,16px)",
  },
  paddingGroup:{
    padding: "10px 20px 0 20px",
  },
}));

const AboutData = () => {
  const { classes, cx } = useStyles();
    return ( 
    <>
      <Box className={classes.dataBox}>
        <Box className={classes.textBox}> 
            <List withPadding >
              <List.Item className={classes.fontClamp}><Anchor title='Jump to Introduction' href="#1">Introduction</Anchor></List.Item>
              <List.Item className={classes.fontClamp}><Anchor title='Jump to Token`s specification' href="#2">Token`s specs</Anchor></List.Item>
              <List.Item className={classes.fontClamp}><Anchor title='Jump to Token`s utility' href="#3">Token`s utility</Anchor></List.Item>
              <List.Item className={classes.fontClamp}><Anchor title='Jump to Contract specification' href="#4">Contract specs</Anchor></List.Item>
              <List.Item className={classes.fontClamp}><Anchor title='Jump to Project`s media' href="#5">Project`s media</Anchor></List.Item>
              <List.Item className={classes.fontClamp}><Anchor title='Jump to FAQ' href="#6">FAQ</Anchor></List.Item>
            </List>
        </Box>
      </Box>

    <Box className={classes.dataBox}>
      <h3 id='1'>Introduction</h3>
      <Box className={classes.textBox}> 
          <Text style={{lineHeight:"2"}} className={cx(classes.textMargin, classes.fontClamp)}>FaithToken is a unique utilitarian svg animated NFT collection. It is dedicated to CryptoEmergency`s ecosystem. It is the first public NFT collection created specifically for the CryptoEmergency project.</Text>
          <Divider className={classes.divider} />
          <Text style={{lineHeight:"2"}} className={cx(classes.textMargin, classes.fontClamp)}>FaithToken collection is a fully on-chain SVG based animated NFT collection. It means there is no external website, nor server, nor IPFS services which keeps the NFT images. Instead thanks to ERC721 extensions all data concerning token attributes and token image is saved inside the smart contract thus data is being stored on the blockchain.</Text>        
      </Box>
      <Divider className={classes.divider} />
      <Group className={classes.paddingGroup}>
        <List withPadding>
          <List.Item className={classes.fontClamp}>No premint.</List.Item>
          <List.Item className={classes.fontClamp}>No reserved NFTs.</List.Item>
          <List.Item className={classes.fontClamp}>No founder free NFTs.</List.Item>
        </List>
        <Image maw={120} mx="auto" radius="md" src='../pics/mint_nft_color.png' alt="pic1" />
      </Group > 
      <Divider className={classes.divider} />
      <Group className={classes.paddingGroup}>
      <Image maw={100} mx="auto" radius="md" style={{zIndex:1}} src='../pics/time_is_money.png' alt="pic2" />
            <List withPadding>
              <List.Item className={classes.fontClamp}>No presale.</List.Item>
              <List.Item className={classes.fontClamp}>No whitelists.</List.Item>
              <List.Item className={classes.fontClamp}>No resale royalties. </List.Item>
            </List>
      </Group>
    </Box>
      <Box className={classes.dataBox}>
        <h3 id='2'>Token`s specs</h3>  
          <Text  className={cx(classes.textMargin, classes.fontClamp)}>Token name is FaithToken. </Text>
            <Divider className={classes.divider} />
          <Text  className={cx(classes.textMargin, classes.fontClamp)}>FaithToken symbol(ticker) is FTH. </Text>
            <Divider className={classes.divider} />
          <Text className={cx(classes.textMargin, classes.fontClamp)}>Total supply is 9999 tokens.</Text>
            <Divider className={classes.divider} />
          <Text className={cx(classes.textMargin, classes.fontClamp)}>With the price of 99 nominated in  
              <Anchor
              title="If you need to buy $CEM"
              href="https://www.bitmart.com/trade/en-US?symbol=CEM_USDT&layout=basic"
              target="_blank"> $CEM.</Anchor>
          </Text>   
            <Divider className={classes.divider} />  
          <Text className={cx(classes.textMargin, classes.fontClamp)}>To prevent NFT claiming abuse and to maximize token social distribution there is a minting amount limitation per each account which is equal to 9 tokens.</Text>
            <Divider className={classes.divider} />  
          <Text className={cx(classes.textMargin, classes.fontClamp)}>All tokens are minted for each account in an individual manner. Every token gets its own unique ID and attributes based on pseudorandom smart contract`s algorithms.</Text>
            <Divider className={classes.divider} />  
          <Text className={cx(classes.textMargin, classes.fontClamp)}>Token attributes are: </Text>
            <List withPadding listStyleType="disc" className={classes.textMargin}>
              <List.Item className={classes.fontClamp}>Unique ID</List.Item>
              <List.Item className={classes.fontClamp}>Description</List.Item>
              <List.Item className={classes.fontClamp}>OnChain SVG image</List.Item>
              <List.Item className={classes.fontClamp}>Feature</List.Item>
                <List withPadding listStyleType="circle">
                    <List.Item className={classes.fontClamp}>faith</List.Item>
                    <List.Item className={classes.fontClamp}>trust</List.Item>
                    <List.Item className={classes.fontClamp}>confidence</List.Item>
                    <List.Item className={classes.fontClamp}>loyalty</List.Item>
                    <List.Item className={classes.fontClamp}>belief</List.Item>
                </List>
              <List.Item className={classes.fontClamp}>Feature`s degree</List.Item>
                <List withPadding listStyleType="circle">
                      <List.Item className={classes.fontClamp}>strong</List.Item>
                      <List.Item className={classes.fontClamp}>diamond</List.Item>
                      <List.Item className={classes.fontClamp}>solid</List.Item>
                      <List.Item className={classes.fontClamp}>durable</List.Item>
                      <List.Item className={classes.fontClamp}>robust</List.Item>
                      <List.Item className={classes.fontClamp}>tough</List.Item>
                </List>
              <List.Item className={classes.fontClamp}>Gradient color angle slop value</List.Item>
              <List.Item className={classes.fontClamp}>First gradient color value</List.Item>
              <List.Item className={classes.fontClamp}>Second gradient color value</List.Item>
              <List.Item className={classes.fontClamp}>Token creation time tag</List.Item>
            </List>
            <Divider className={classes.divider} />  
          <Text className={cx(classes.textMargin, classes.fontClamp)}>Token possible variation is equal to 351 866 700. Which gives only 9999/351 866 700 = 0,0028 % chance of getting overlapping attributes.</Text>
            <Divider className={classes.divider} />  
          <Text className={cx(classes.textMargin, classes.fontClamp)}>Token image is native to any browser. Browser should render your image without any issue. Image is scalable thanks to svg technology thus you will never loose image quality. Every time inserting your image to your browser page title will always tell your token name.</Text>
      
    </Box>

    <Box className={classes.dataBox}>
      <h3 id='3'>Token`s utility</h3>

          <Text style={{lineHeight:"2"}} className={cx(classes.textMargin, classes.fontClamp)}>FaithToken collection is a fully on-chain SVG based animated NFT collection. It means there is no external website, nor server, nor IPFS services which would keep the NFT images. Instead thanks to ERC721 extensions all data concerning token attributes and token image is saved inside the smart contract thus data is being stored on the blockchain.</Text>
    
    </Box>
    <Box className={classes.dataBox}>
      <h3 id='4'>Contract`s specs</h3>
      <Text style={{lineHeight:"2"}} className={cx(classes.textMargin, classes.fontClamp)}>FathTokenContract is deployed to CryptoEmergency own native 
        <Anchor
              title="CryptoEmergency chain ID"
              href="https://chainlist.org/chain/193"
              target="_blank"> blockchain</Anchor>
          .</Text>  
        <Divider className={classes.divider} />
      <Text className={cx(classes.textMargin, classes.fontClamp)}>Contract address is 
          <Anchor
              title="Link to smart contract"
              href={`https://cemscan.com/address/${process.env.REACT_APP_CONTRACT_ADDRESS}/transactions`}
              target="_blank"> {process.env.REACT_APP_CONTRACT_ADDRESS}
          </Anchor>
          .</Text>
        <Divider className={classes.divider} />
      <Text className={cx(classes.textMargin, classes.fontClamp)}>Contract inherits from ERC721URIStorage and ERC721Enumerable extensions of ERC721 standart.</Text>
  
    </Box>
    <Box className={classes.dataBox}>
      <h3 id='5'>Media</h3>
      <Text className={cx(classes.textMargin, classes.fontClamp)}>
          <Anchor
              title="Link to CryptoEmergency"
              href="https://www.crypto-emergency.com"
              target="_blank">CryptoEmergency
          </Anchor></Text> 
        <Divider className={classes.divider} />
      <Text style={{lineHeight:"2"}} className={cx(classes.textMargin, classes.fontClamp)}> 
        <Anchor
              title="Project`s twitter account"
              href="https://twitter.com/NftEmergency"
              target="_blank">Twitter</Anchor>
      </Text>  
        <Divider className={classes.divider} />
        <Text className={cx(classes.textMargin, classes.fontClamp)}>Discord</Text> 
    </Box>

    <Box className={classes.dataBox}>
      <h3 id='6'>FAQ</h3>
      <Text className={cx(classes.textMargin, classes.fontClamp)}>Q1. How can I switch Dark|Light mode?</Text>
      <Text className={cx(classes.textMargin, classes.fontClamp)}>A1. You can click Sun|Moon icon at the right bottom corner. You can also use Ctrl+J or Cmd+J (for IOS users). Any of theese actions will save Dark|Light mode for you.</Text>
        <Divider className={classes.divider} />
      <Text ta="center" fz={14} style={{marginTop: "10px", lineHeight:"2"}}>Site was created with <Anchor href='https://mantine.dev/' target="_blank">Mantine UI</Anchor>.</Text>      
    </Box> 
    </>  );
}
 
export default AboutData;

// <Text>No presale.</Text>
//       <Text>No premint.</Text>
//       <Text>No whitelists. </Text>
//       <Text>No freemint for the contract owner.</Text>
//       <Text>No reserved NFTs.</Text>
//       <Text>No resale royalties.</Text>
  // <Anchor
  // title="CryptoEmergency chain ID"
  // href="https://chainlist.org/chain/193"
  // target="_blank"> blockchain</Anchor>
  // .</Text>            
  // <Text ta="left" style={{lineHeight:"2"}} className={cx(classes.textMargin, classes.fontClamp)}>Holding an FTH is going to bring several benefits to its owner, such as: </Text>
  //         <List withPadding listStyleType="disc" className={classes.textMargin}>
  //         <List.Item className={classes.fontClamp}>Special ranking on the CryptoEmergency social platform.</List.Item>
  //         <List.Item className={classes.fontClamp}>Different subscriptions.</List.Item>
  //         <List.Item className={classes.fontClamp}>Strong Holders Owners. Newly ICO projects listing will distribute their tokens to FTH holders.</List.Item>
  //       </List>
  //       <Divider className={classes.divider} />