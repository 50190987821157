
import React from 'react';
import { Link } from 'react-router-dom';
import {Center} from "@mantine/core";

const NotFound : React.FC = () => {
    return ( 
      <Center>
         <h1>
            Page does not exist. Please return to <Link to="/">Main page</Link>.
         </h1> 
      </Center>
     );
}
 
export default NotFound;