import React from 'react';
import {Box} from "@mantine/core";
import AboutData from '../components/AboutData';

const About: React.FC = () => {

    return ( 
      <>
        <Box style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          //minWidth: 500,
          flexWrap: 'nowrap',
          }}>

       
         <AboutData/> 

      </Box>
      </>
     );
}
 
export default About;
